import React from 'react'
import App from 'App'
import { SITE_LINK_PUBLISHING } from 'consts'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import imageHeader from 'static/images/integrations/bluesky/header_picture.webp'
import imageToolManage from 'static/images/integrations/bluesky/tools_to_manage.webp'
import imageText1 from 'static/images/integrations/bluesky/image_text_1.webp'
import imageText2 from 'static/images/integrations/bluesky/image_text_2.webp'
import imageText3 from 'static/images/integrations/bluesky/image_text_3.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import { Flex } from 'components/Layout'
import PageHeader from './components/PageHeader'
import ToolManageSection from './components/ToolManageSection'
import TextImageSection from './components/TextImageSection'
import CoreFeaturesSection from './components/CoreFeaturesSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'

const NETWORK = 'Bluesky'

const TEXT_IMAGES = [
  {
    header: {
      title: `Simplify ${NETWORK} Content Posting`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText1,
    items: [
      {
        title: 'Easily plan and publish posts',
        description: `Draft posts for one or many ${NETWORK} profiles — publish text and article posts, attach images from a media Library, include mentions, hashtags and links.`,
      },
      {
        title: 'Manage the content calendar',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar—quickly filter by tag, profile and date.`,
      },
      {
        title: 'Implement approval workflows',
        description:
          'Vista’s built-in approval workflow is perfect for legal, compliance, agency collaboration or peer review.',
      },
    ],
    linkText: 'MORE ON PUBLISHING',
    link: SITE_LINK_PUBLISHING,
  },
  {
    header: {
      title: `Create authentic connections at scale`,
      matches: [],
    },
    textFirst: true,
    image: imageText2,
    items: [
      {
        title: `Manage the social inbox as a team`,
        description:
          'Prevent duplication of efforts with team tools—mark as complete, view audit trails and track real-time progress.',
      },
      {
        title: 'Stay productive with intuitive workflows',
        description: `Streamline ${NETWORK} management with message tagging, task routing and real-time status updates for team wide visibility.`,
      },
      {
        title: 'Easily customize profile & governance',
        description:
          'Organize teams and profiles to fit your needs—great for agencies, multi-location or global businesses.',
      },
    ],
  },
  {
    header: {
      title: `Gather strategic insights with ${NETWORK} analytics`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText3,
    items: [
      {
        title: `Visualize ${NETWORK} profile data`,
        description:
          'Understand likes, shares, comments and views behavior with data visualizations and dynamic charts.',
      },
      {
        title: 'Identify your best content',
        description: `Dig into ${NETWORK} Insights data and identify best performing content.`,
      },
      {
        title: 'Customized and export reports',
        description: 'Export insightful reports or customize and build your own to deliver to your stakeholders.',
      },
    ],
  },
]

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools for business`}
        description="Dynamically engage your audience with a powerful set of tools to manage your Blusky profiles and messenger conversations."
        path="/integrations/bluesky/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools for business`}
        description={
          "It's time to own your content and audience on decentralized social media. Easily build your community on Bluesky and beyond with Vista Social's flexible planning, posting, engagement and analytics tools."
        }
        image={imageHeader}
      />

      <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
        <G2FeaturesComponent />
      </Flex>

      <ToolManageSection
        network={NETWORK}
        headerText={`Tools to Manage Multiple ${NETWORK} profiles`}
        description={`Vista Social’s ${NETWORK} integration includes publishing, reporting and comment management tools. Plan content, engage your audience, collaborate as a team and measure efforts from a single platform.`}
        image={imageToolManage}
        tools={[
          {
            title: `Create engaging ${NETWORK} content`,
            description: `Plan, create and publish engaging posts with a centralized content calendar, intuitive workflows and creative tools for ${NETWORK} publishing.`,
          },
          {
            title: 'Manage conversations & build relationships',
            description:
              'Never miss a comment, wall post, Review or Messenger conversation with a unified inbox and powerful social CRM tools for engagement.',
          },
          {
            title: `Benchmark your ${NETWORK} performance`,
            description: `Gather paid and organic insights for one or many ${NETWORK} Pages and Groups to measure fan growth, analyze engagement and track post performance.`,
          },
        ]}
      />

      <TextImageSection textImages={TEXT_IMAGES} />

      <CoreFeaturesSection headerTitle={`Take your ${NETWORK} Marketing to the Next Level`} matches={[NETWORK]} />

      <FaqSection type="bluesky" />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
